import React from 'react';
import {graphql} from "gatsby";
import {PageWrapper, Container, Title} from "styles/page";
import {Sidebar} from "components";
import {SliceMachine} from "@HichiHachi/react-slices";
import {GetSocials} from 'utils/convertData';
import Seo from "utils/seo";

export const query = graphql`
    query Blog_postQuery($id: String) {
        prismicBlogPost(id: {eq: $id}) {
                ...blogPostQuery
        }
        prismicSidebar {
            ...sidebarFragment
        }
        prismicNavigation {
            data {
                ...socialQuery
            }
        }
    }
`;

const BlogTemplate = ({data})  => {
    const postData = data?.prismicBlogPost?.data;

    const sidebarActive = postData?.sidebar;
    const sidebarData = data?.prismicSidebar?.data;

    const title = postData?.title;
    const metaTitle = postData?.meta_title;
    const metaDescription = postData?.meta_description;
    const metaImage = postData?.meta_image;
    
    const social = GetSocials(data);
    const context = {social}

    const slices = data?.prismicBlogPost?.data?.body;
    const unpublished = data?.prismicBlogPost?.first_publication_date;
    return (
        <>
            <Seo 
                title={metaTitle ? metaTitle : null}
                description={metaDescription ? metaDescription : null}
                image={metaImage ? metaImage.url : null}
            />
            <PageWrapper>
                <Container>
                    <Title>{title}</Title>
                    <SliceMachine body={slices} context={context}/>
                </Container>
                {sidebarActive && !!unpublished && <Sidebar data={sidebarData} social={social}/>}
            </PageWrapper>
        </>
    )
}

export default BlogTemplate
